import './Events.css';
import React, { useState } from 'react';
import "@aws-amplify/ui-react/styles.css";
import {
  Heading,
  View
} from "@aws-amplify/ui-react";
import { List } from 'semantic-ui-react'
import Navbar from '../../components/Navbar/Navbar';
import PurchaseLinks from '../../components/PurchaseLinks/PurchaseLinks';
import Gallery from '../../components/Gallery/Gallery';
import CategoryMenu from '../../components/CategoryMenu/CategoryMenu';
import image1 from '../../images/Book-signing-1.jpg';
import image2 from '../../images/Book-signing-2.jpg';
import image3 from '../../images/Book-signing-3.jpg';
import image4 from '../../images/Book-signing-4.jpg';
import image5 from '../../images/Book-signing-5.jpg';
import image6 from '../../images/Book-signing-6.jpg';
import image7 from '../../images/Book-signing-7.jpg';
import image8 from '../../images/cc-talk-1.jpg';
import image9 from '../../images/cc-talk-2.jpg';
import image10 from '../../images/cc-talk-3.jpg';
const images = [
    { 
        imageUrl: image1,
        id: 'BS1',
        category: 'October 13th Book Signing',
    },
    {
        imageUrl: image2,
        id: 'BS2',
        category: 'October 13th Book Signing',
    },
    {
        imageUrl: image3,
        id: 'BS3',
        category: 'October 13th Book Signing',
    },
    {
        imageUrl: image4,
        id: 'BS4',
        category: 'October 13th Book Signing',
    },
    {
        imageUrl: image5,
        id: 'BS5',
        category: 'October 13th Book Signing',
    },
    {
        imageUrl: image6,
        id: 'BS6',
        category: 'October 13th Book Signing',
    },
    {
        imageUrl: image7,
        id: 'BS7',
        category: 'October 13th Book Signing',
    },
    {
        imageUrl: image8,
        id: 'cc1',
        category: 'January 2024 Castecottage talk with K Cettes',
    },
    {
        imageUrl: image9,
        id: 'cc2',
        category: 'January 2024 Castecottage talk with K Cettes',
    },
    {
        imageUrl: image10,
        id: 'ccc3',
        category: 'January 2024 Castecottage talk with K Cettes',
    },
]

function Events () {
    const categories = [...new Set(images.map((image) => image.category))];
    const [activeCategory, setActiveCategory] = useState(categories[0]);
    const handleSelectCategory = (category) => {
    setActiveCategory(category);
    };
    const filteredImages = images.filter(
        (image) => image.category === activeCategory
    );
    return (
        <View className="events-page">
            <Navbar buttonClass="events-nav-button"></Navbar>
            <Heading level={1} className="events-header">Amanda Lee Keller</Heading>
            <View className="events-container">
                <Heading level={2} className="events-head">Upcoming Events</Heading>
                <List className="upcoming-events-list">
                    <List.Item className="events-li">No upcoming events</List.Item>
                </List>
            </View>
            <View className="events-container">
                <Heading level={2} className="events-head">Previous Events</Heading>
                <CategoryMenu
                categories={categories}
                activeCategory={activeCategory}
                onSelectCategory={handleSelectCategory}
                />
                <Gallery images={filteredImages} />
            </View>
            <PurchaseLinks></PurchaseLinks>
        </View>
    )
}

export default Events