import React from 'react';
import {
  BrowserRouter,
  Routes,
  Route
} from 'react-router-dom';
import Home from './pages/Home/Home'
import About from './pages/About/About'
import CastleCottage from './pages/CastleCottage/CastleCottage'
import UpcomingWorks from './pages/UpcomingWorks/UpcomingWorks'
import Events from './pages/Events/Events'
import './App.css';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />}/>
        <Route path="/about" element={<About />}/>
        <Route path="/castlecottage" element={<CastleCottage />}/>
        <Route path="/upcoming" element={<UpcomingWorks />}/>
        <Route path="/events" element={<Events />}/>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
