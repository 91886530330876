import './UpcomingWorks.css';
import React from 'react';
import "@aws-amplify/ui-react/styles.css";
import {
  Heading,
  View
} from "@aws-amplify/ui-react";
import { List } from 'semantic-ui-react'
import Navbar from '../../components/Navbar/Navbar';
import PurchaseLinks from '../../components/PurchaseLinks/PurchaseLinks';

function UpcomingWorks () {
    return (
        <View className="upcoming-page">
            <Navbar buttonClass="upcoming-nav-button"></Navbar>
            <Heading level={1} className="upcoming-header">Amanda Lee Keller</Heading>
            <View className="upcoming-container">
                <Heading level={2} className="upcoming-head">Upcoming Works</Heading>
                <List className="upcoming-works-list">
                    <List.Item className="upcoming-li">Jesse Tree</List.Item>
                    <List.Item className="upcoming-li">Pray For Us Sinners</List.Item>
                    <List.Item className="upcoming-li">Isabella Kelleher</List.Item>
                    <List.Item className="upcoming-li">Josephine's Book</List.Item>
                    <List.Item className="upcoming-li">Fist Full of Dolors</List.Item>
                    <List.Item className="upcoming-li">Shenanigans</List.Item>
                </List>
            </View>
            <PurchaseLinks></PurchaseLinks>
        </View>
    )
}

export default UpcomingWorks