import './Navbar.css';
import React from 'react';
import "@aws-amplify/ui-react/styles.css";
import { Link } from 'react-router-dom';
import {
  View
} from "@aws-amplify/ui-react";

function Navbar (props = {buttonClass: "nav-button"}) {
    return (
        <View className="navbar-container">
            <div className="nav-list">
                <Link to="/">
                    <button className={props.buttonClass}>
                        Home
                    </button>
                </Link>
                <Link to="/about">
                    <button className={props.buttonClass}>
                        Author Bio
                    </button>
                </Link>
                <Link to="/castlecottage">
                    <button className={props.buttonClass}>
                        Castlecottage
                    </button>
                </Link>
                <Link to="/upcoming">
                    <button className={props.buttonClass}>
                        Upcoming Works
                    </button>
                </Link>
                <Link to="/events">
                    <button className={props.buttonClass}>
                        Events
                    </button>
                </Link>
            </div>
        </View>
    )
}

export default Navbar