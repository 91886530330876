import './Home.css';
import React from 'react';
import "@aws-amplify/ui-react/styles.css";
import backgroundImage from '../../images/11595916.jpg';
import {
  Heading,
  View,
  Text
} from "@aws-amplify/ui-react";
import Navbar from '../../components/Navbar/Navbar';
import PurchaseLinks from '../../components/PurchaseLinks/PurchaseLinks';
import ContactForm from '../../components/ContactForm/ContactForm';

function Home () {
    return (
        <View className="homepage">
            <img
            className="background-image"
            src={backgroundImage}
            alt=""
            />
            <Navbar buttonClass="home-nav-button"></Navbar>
            <Heading level={1} class="home-header">Amanda Lee Keller</Heading>
            <View className="mission-container">
                <Text class="mission-title">Mission of My Writings</Text>
                <Text class="mission-text">
                To craft stories that are entertaining, relatable, and uplifting told through the
                lens of a Catholic perspective. My goal is to not only touch the Catholic
                community but all faithful Christians by giving them a new appreciation and understanding of the faith they 
                have been raised in, inspiring them to live a Christian lifestyle as opposed to 
                a secular lifestyle. We are in this world but not of this world. Through my 
                characters I hope to open hearts to the message of Jesus Christ who, unlike the 
                secular world, never breaks His promises.
                </Text>
            </View>
            <PurchaseLinks></PurchaseLinks>
            <ContactForm></ContactForm>
        </View>
    )
}

export default Home