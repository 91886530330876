import './About.css';
import React from 'react';
import "@aws-amplify/ui-react/styles.css";
import headshot from '../../images/headshot.jpg';
import {
  Heading,
  View,
  Text
} from "@aws-amplify/ui-react";
import Navbar from '../../components/Navbar/Navbar';
import PurchaseLinks from '../../components/PurchaseLinks/PurchaseLinks';

function About () {
    return (
        <View className="about-page">
            <Navbar buttonClass="about-nav-button" className="navbar"></Navbar>
            <Heading level={1} className="name-header">Amanda Lee Keller</Heading>
            <View className="about-container">
                <img
                className="headshot-image"
                src={headshot}
                alt=""
                />
                <Text className="about-text">
                    Amanda grew up in Old Town, Alexandria, VA where she attended St. Mary’s Catholic school. 
                    She is a graduate of the College of William & Mary and earned her MFA in Screenwriting from 
                    the University of Southern California.  Amanda met her husband, August Keller, when she moved 
                    to Richmond following graduate school.  They married in Richmond, and raised their three 
                    daughters, Wilson, Cannan and Cary. Amanda and August still live in Richmond.  Over a span of 
                    20+ years Amanda’s parents owned a home in Clonmel, Ireland where the Keller’s and other 
                    family members summered.  The bonds with the people of the town and surrounding counties 
                    influenced many of the characters of Castlecottage.  Castlecottage is Amanda's first published novel.
                </Text>
            </View>
            <PurchaseLinks></PurchaseLinks>
        </View>
    )
}

export default About