import './Gallery.css';
import React from 'react';
import "@aws-amplify/ui-react/styles.css";
import {
    View
  } from "@aws-amplify/ui-react";

function Gallery (props = {images: {}}) {
    return (
        <View className="gallery">
            {props.images.map((image) => (
            <img className="gallery-image" key={image.id} src={image.imageUrl} alt={`Img ${image.id}`} />
            ))}
        </View>
    );
};
export default Gallery;