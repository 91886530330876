import React from 'react';
import { Form, Input, TextArea, Button } from 'semantic-ui-react';
import Swal from 'sweetalert2';
import emailjs from 'emailjs-com';
import './ContactForm.css';
import {
    Text,
  } from "@aws-amplify/ui-react";

const SERVICE_ID = "service_vj9zkeg";
const TEMPLATE_ID = "template_8hkeuar";
const PUBLIC_KEY = "rolIvRg4_r37w5-B3";

function ContactForm () {
    const handleOnSubmit = (e) => {
        e.preventDefault();
        emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, e.target, PUBLIC_KEY)
          .then((result) => {
            console.log(result.text);
            Swal.fire({
              icon: 'success',
              title: 'Message Sent Successfully'
            })
          }, (error) => {
            console.log(error.text);
            Swal.fire({
              icon: 'error',
              title: 'Ooops, something went wrong',
              text: error.text,
            })
          });
        e.target.reset()
      };
    return (
        <div className='Form'>
          <Form onSubmit={handleOnSubmit}>
          <Text className="form-title">Contact The Author:</Text>
            <Form.Field
              id='form-input-control-email'
              control={Input}
              label='Email'
              name='reply_to'
              placeholder='Email…'
              required
              icon='mail'
              iconPosition='left'
            />
            <Form.Field
              id='form-input-control-last-name'
              control={Input}
              label='Name'
              name='from_name'
              placeholder='Name…'
              required
              icon='user circle'
              iconPosition='left'
            />
            <Form.Field
              id='form-textarea-control-opinion'
              control={TextArea}
              label='Message'
              name='message'
              placeholder='Message…'
              required
              className="form-input-two"
            />
            <Button type='submit' color='teal'>Submit</Button>
          </Form>
        </div>
      );
}

export default ContactForm;