import './PurchaseLinks.css';
import React from 'react';
import "@aws-amplify/ui-react/styles.css";
import materMedia from '../../images/mater-media-logo.png';
import amazonButton from '../../images/amazon-button.png';
import {
  View,
  Text
} from "@aws-amplify/ui-react";

function PurchaseLinks () {
    return (
        <View className="purchase-links">
            <Text className="purchase-text">Checkout her first work, Castlecottage:</Text>
            <ul class="link-list">
            <li><a href="https://www.matermedia.org/shop" target="_blank" rel="noopener noreferrer"><img src={materMedia} className="materMediaIcon" /></a></li>
            <li><a href="https://www.amazon.com/Castlecottage-Amanda-Lee-Keller/dp/B0CCXPGD5Z/ref=sr_1_1?crid=3TD2A5W6V1C7K&keywords=castlecottage+by+amanda+keller&qid=1694393520&sprefix=castlecottage+%2Caps%2C81&sr=8-1#customerReviews" target="_blank" rel="noopener noreferrer"><img src={amazonButton} className="amazonIcon" /></a></li>
            </ul>
        </View>
    )
}

export default PurchaseLinks