import './CastleCottage.css';
import React from 'react';
import "@aws-amplify/ui-react/styles.css";
import backgroundImage from '../../images/Clover-knot-green.png';
import coverImage from '../../images/Castlecottage-cover-front.jpg';
import materMedia from '../../images/mater-media-logo.png';
import amazonButton from '../../images/amazon-button.png';
import {
  Heading,
  View,
  Text
} from "@aws-amplify/ui-react";
import Navbar from '../../components/Navbar/Navbar';

function CastleCottage () {
    let srcUrl = `https://www.youtube.com/embed/TznUSDzaVig`
    return (
        <View class="castlecottage">
            <img
            class="cc-background-image"
            src={backgroundImage}
            alt=""
            />
            <Navbar buttonClass="nav-button"></Navbar>
            <Heading level={1} className="castlecottage-heading">Castlecottage</Heading>
            <View className="blurb-container">
                <img
                className="cover-image"
                src={coverImage}
                alt=""
                />
                <View class="text-contain">
                <Text class="blurb-title">About the Book</Text>
                <Text class="blurb-text">
                Through a whimsical tale dripping with Irish lore, authentic dialect, humor, and charm, 
                author Amanda Keller illustrates that beauty and power can be found in every stage of life.
                In the charming town of Clonmel, Ireland, Josephine, a beloved faith-healer, is preparing 
                for her eternal reward. But as she makes her last confession and readies to meet her Maker, 
                her world is suddenly invaded by two relatives from America—her grown grand niece, Micheleen 
                with her precocious 7-year-old daughter, Maeve.
                Micheleen, newly divorced, has landed a job as an executive with an international cosmetics 
                company. Totally uninvited, she and Maeve move in to “care for” Josephine.
                A clash of cultures erupts between young Maeve, attached to her cell phone and video games, 
                and her septuagenarian great-grand aunt who does not have a phone—any kind of phone—nor any 
                affection for newfangled devices.
                But seeing Maeve depressed by the separation of her parents, Josephine employs faith and 
                courage in a reunion mission, receiving natural and supernatural support from Fergus the 
                barkeep/undertaker, the holy and beleaguered friar, Father Adrian, and a host of life-long 
                residents of the old sod.
                Surprising twists serve to highlight the value of redemptive suffering and perseverance, 
                knitting mystical experiences on earth with eternal transformation.
                </Text>
                </View>
            </View>
            <View className="review-container">
                <Text class="review-title">Reviews</Text>
                <Text class="review-text">
                "This book Castlecottage was the best book I have read all year! This inspiring story is 
                set in a small village in Ireland and it’s people all have unique personalities but all 
                mesh peaceably in this quaint setting. The main character is indeed a character, she’s 
                feisty, funny and holds her Catholic Faith as a priceless gift, but a gift she shares 
                with all. The author makes the reader feel they have known the villagers all their lives 
                and have walked down the streets, into the pub, to the Friary and up to the Holy Place 
                on stop of the green hills with them many times. There is an uproar in the village when 
                American relatives visit but then the fun really starts. This book made me laugh out 
                loud and cry big tears, I didn't want put it down and I didn't want it to end. Sure hope 
                there is a sequel coming this way some day!!" -- Camp, USA Amazon Review
                </Text>
                <Text class="review-text">
                "A faith-filled Irish tale about a devote elderly and dying Catholic servant of God who 
                helped her loved ones grow closer to God. With the help of her priest, the Blessed Mother 
                and through tales of leprechauns and fairies she nurtured her 7-year-old great-grand 
                niece along on her faith journey." -- Gary Port, USA Amazon Review
                </Text>
                <Text class="review-text">
                "Just finished CastleCottage. What a story....profoundly poignant. I'm speechless with 
                feelings and a zillion thoughts swirling around in my Irish mind heart and soul." 
                -- Connie, St. Louis
                </Text>
            </View>
            <View className="Video-Player">
                <div class="video-container">
                <iframe
                    class="responsive-iframe"
                    src={srcUrl}
                    frameborder="0"
                    allow="autoplay; encrypted-media"
                    allowfullscreen
                    title="video"
                />{" "}
                </div>
            </View>
            <View className="purchase-links">
            <Text className="purchase-text">Checkout it out here:</Text>
            <ul class="link-list">
            <li><a href="https://www.matermedia.org/shop" target="_blank" rel="noopener noreferrer"><img src={materMedia} className="materMediaIcon" /></a></li>
            <li><a href="https://www.amazon.com/Castlecottage-Amanda-Lee-Keller/dp/B0CCXPGD5Z/ref=sr_1_1?crid=3TD2A5W6V1C7K&keywords=castlecottage+by+amanda+keller&qid=1694393520&sprefix=castlecottage+%2Caps%2C81&sr=8-1#customerReviews" target="_blank" rel="noopener noreferrer"><img src={amazonButton} className="amazonIcon" /></a></li>
            </ul>
        </View>
        </View>
    )
}

export default CastleCottage