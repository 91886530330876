import './CategoryMenu.css';
import React, { useRef, useEffect } from "react";
import "@aws-amplify/ui-react/styles.css";
import {
    View,
    Button
  } from "@aws-amplify/ui-react";

function CategoryMenu (props = { categories: ['October 13th Book Signing', 'Talk on Castlecottage with K Cettes'], activeCategory: '', onSelectCategory: {} }) {
    const submenuRef = useRef(null);

    const handleScroll = () => {
            if (submenuRef.current) {
                const submenuWidth = submenuRef.current.clientWidth;
                const submenuScrollWidth = submenuRef.current.scrollWidth;
            if (submenuScrollWidth > submenuWidth) {
                submenuRef.current.scrollRight = submenuScrollWidth + submenuWidth;
            }
            else {
                submenuRef.current.scrollRight = 0;
            }
        }
    };
    
    useEffect(() => {
    handleScroll();
    }, []);
    
    return (
        <div className="submenu-wrapper">
            <View className="submenu" ref={submenuRef} id="id">
                {props.categories.map((category, index) => (
                <Button
                    key={category}
                    onClick={() => props.onSelectCategory(category, index)}
                    className={`${category === props.activeCategory ? "active" : ""} ${
                    index > props.categories.indexOf(props.activeCategory) ? "category-at-right" : "category-at-left"
                    }`}
                    >
                    {category}
                </Button>
                ))}
            </View>
        </div>
        );
    };
export default CategoryMenu;